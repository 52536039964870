<template>
  <div>
    <a-row type="flex" justify="center" style="margin-left: -10%; min-height: calc(100vh - 30px);">
      <a-form style="width: 600px; margin: 30px auto" :form="form" @submit="submit" :colon="false">
        <a-form-item v-bind="formItemLayout" label=" ">
          <h1 style="text-align: center; padding-top: 70px">重 置 密 码</h1>
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="用户名">
          <a-input v-decorator="['name', { rules }]" />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="绑定手机号">
          <a-input
            addon-before="+86"
            :maxLength="11"
            style="width: 100%"
            v-decorator="['phone', { rules: [
              { required: true, message: '请填写手机号' },
              { len: 11, message: '手机号长度仅限 11 位' },
            ] }]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="验证码">
          <a-row :gutter="8">
            <a-col :span="15">
              <a-input v-decorator="['verification', { rules }]" />
            </a-col>
            <a-col :span="5">
              <a-button
                :disabled="disabled"
                @click="getCode"
              >获取验证码{{ countDown>0 ? `（${countDown}）` : '' }}</a-button>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="新密码">
          <a-input v-decorator="['password', { rules }]" />
        </a-form-item>
        <!-- <a-form-item v-bind="tailFormItemLayout">
          <a-checkbox v-decorator="['agreement', { valuePropName: 'checked' }]">
            I have read the
            <a href>agreement</a>
          </a-checkbox>
        </a-form-item>-->
        <a-form-item v-bind="tailFormItemLayout">
          <div style="text-align: center;">
            重置完成后，需重新登录商城系统
            <a-button type="primary" size="large" style="width: 100%" html-type="submit">确认重置</a-button>
          </div>
        </a-form-item>
        <a-form-item v-bind="tailFormItemLayout">
          <div style="text-align: center;">
            <a-button size="large" style="width: 100%" @click="$router.go(-1)">返回</a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-row>
    <div style="width:100%; height:30px">
      <h5 style="width: 650px; margin: 0 auto;">
        <copyright />
      </h5>
    </div>
  </div>
</template>

<script>
import Copyright from '@/component/home/Copyright.vue'
const rules = [{ required: true, message: '必填字段' }]
export default {
  components: {
    Copyright
  },

  data() {
    return {
      rules,
      countDown: 0, // 验证码倒计时
      disabled: false, // 验证码按钮禁用
      autoCompleteResult: [],
      formItemLayout: {
        labelCol: { sm: { span: 6 } },
        wrapperCol: { sm: { span: 18 } }
      },
      tailFormItemLayout: {
        wrapperCol: { sm: { offset: 6 } }
      }
    }
  },

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'register' })
  },

  methods: {
    getCode() {
      this.form.validateFields(['phone'], (err, values) => {
        if (!err) {
          this.disabled = true
          this.post('sendSMS', {
            phone: values.phone
          })
            .then(() => {
              this.$message.success('发送成功，请查收')
              let countDown = 60
              const timer = setInterval(() => {
                this.countDown = countDown
                if (countDown-- === 0) {
                  clearInterval(timer)
                  this.disabled = false
                }
              }, 1000)
            })
            .catch((err) => {
              this.$message.error(err.msg)
              this.disabled = false
            })
        }
      })
    },
    submit(e) {
      e.preventDefault()
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.post('resetPasswordForFranchisee', values)
            .then((res) => {
              this.$message.success('密码重置成功')
              this.delCookie('FRANCHISEETOKEN')
              this.delCookie('FRANCHISEEKEY')
              this.delSession('user')
              this.$router.replace('login')
            })
            .catch((err) => {
              if (err.code === 4004) {
                this.$message.error('新密码不能与原密码相同')
              } else {
                this.$message.error(err.msg)
              }
            })
        }
      })
    }
  }
}
</script>
